 <template>
    <div class="d-flex gap-2 my-2">
    <ShareNetwork
        network="facebook"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
        <i class="fab fah fa-lg fa-facebook" style="color: #3b5998 ;"> </i>
        </ShareNetwork>
        <ShareNetwork
        network="twitter"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
        <i class="fab fah fa-lg fa-twitter" style="color: #00acee ;"></i>
        </ShareNetwork>
        <ShareNetwork
        network="email"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
        <i class="fa fah fa-lg fa-envelope" style="color: rgb(31, 44, 235) ;"></i>
        </ShareNetwork>
        <ShareNetwork
        network="linkedin"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
        <i class="fab fah fa-lg fa-linkedin" style="color: #0A66C2 ;"></i>
        </ShareNetwork>
        <ShareNetwork
        network="messenger"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
       
        <i class="fab fah fa-lg fa-facebook-messenger" style="color: #006AFF ;"></i>
        </ShareNetwork>
        <ShareNetwork
        network="telegram"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
        <i class="fab fah fa-lg fa-telegram" style="color: #0088cc ;" ></i>
        </ShareNetwork>
        <ShareNetwork
        network="whatsapp"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
        <i class="fab fah fa-lg fa-whatsapp" style="color: #075e54 ;"></i>
        </ShareNetwork>
        
        <ShareNetwork
        network="pinterest"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :media="image"
        >
        <i class="fab fah fa-lg fa-pinterest" style="color: #E60023 ;"> </i>
    </ShareNetwork>
    </div>
 </template>
<script>
export default {
    name: "ShareSocial",
    props: {
        image: { type: [String,null], default: "" },
        title: { type: [String,null], default: "" },
        description: { type: [String,null], default: "" },
    },
    data() {
     return {
        url : decodeURIComponent(window.location.href),
    };
  },
}
</script>