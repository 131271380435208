<template>
  <div class="project-progress py-2 mx-2" :id="'project' + randid">
    <div class="project-progress-box" v-bind:class="setProgressColor()">
      <div
        class="project-progress-bar"
        
        :style="{ width: width + '%' }"
      >
        <div class="project-progress-value">{{ percentext }}%</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "progress-bar",
  props: {
    project_id: {
      type: Number,
      default: 0,
    },
    percentage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      width: 0,
      randid: Math.floor(Math.random() * 1000000),
      percentext: this.percentage,
      percent :  this.percentage
    };
  },
  methods: {
    setProgressColor() {
      if (this.width >= 80) {
        return "progress-color-green";
      } else if (this.width >= 50) {
        return "progress-color-yellow";
      } else if (this.width >= 30) {
        return "progress-color-blue";
      } else {
        return "progress-color-red";
      }
    },
    progressAnimate(newNumber, oldNumber) {
      let _this = this;
      //console.log(newNumber, oldNumber);
        let interval = setInterval(function () {
        if (oldNumber > newNumber) {
          _this.width--;
          if (_this.width <= newNumber) {
            clearInterval(interval);
          }
        } else {
          _this.width++;
          if (_this.width >= newNumber) {
            clearInterval(interval);
          }
        }
      }, 10);
    },
   
    loadProgressBar() {
        let _this = this;

        let loadingInterval = setInterval(function () {
        if (_this.project_id) {
          axios
            .get("get-progress", { params: { id: _this.project_id } })
            .then((response) => {
              if (response.status === 200) {
               _this.percent = response.data.percent;
               //response.data = {"currency":"KD","percent": Math.floor(Math.random() * _this.percent),"text": Math.floor(Math.random() * _this.percent),"target":Math.floor(Math.random() * 10000),"total": Math.floor(Math.random() * 200),"beneficiaries":"200000000","residual": Math.floor(Math.random() * 50000),"donares":"2"}
                _this.$root.$emit("updateProjectAmountDetails",response.data,_this.project_id);
              }
              if (_this.percent >= 100) {
                clearInterval(loadingInterval);
              }
            });
        }
      }, 1200000);
    },
    runProgress() {
      if (this.width < this.percent) {
        this.progressAnimate(this.percent, this.width);
      }
    },
  },
  created() {
    this.loadProgressBar();
    this.runProgress();
    // window.addEventListener("scroll", this.runProgress);
  },

  watch: {
      percentage : function (newValue){
          this.percent = newValue;
      },
      percent: function (newValue, oldValue) {
      this.progressAnimate(newValue, oldValue);
    },
    width: function (newValue) {
      if (newValue > 100) {
        this.percentext = "100";
      } else {
        this.percentext = newValue;
      }
    },
  },
  computed: {
    // opt. 2
  },
};
</script>