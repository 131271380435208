<template>
  <div>
    <loading :isShowLoading="isloading"></loading>
    <div class="page-content py-5">
      <div class="container">
        <div class="d-flex align-items-center">
            <div class=" m-3">
                <img v-if="service.image !== undefined" class="w-100 w-lg-50 rounded-20" :src="service.image.medium" :alt="service.title" :srcset="service.image.medium_large">
            </div>
            <div>
                <h2 class="h3">{{service.title}}</h2>
                <div class="text-muted fs-10 text-justify">{{service.content}}</div>
                <ShareSocial :image="sahreImage" :title="service.title" :description="service.content"></ShareSocial>
            </div>
        </div>
        <hr class="dropdown-divider">
        <div class="">
            <div  class="row align-items-center">
                <div class="col-12 col-md-4 align-items-center " v-for="(item,index) in service.service " :key="index"  data-aos="zoom-in">
                   <div class="flex-fill d-flex gap-2 bg-white rounded my-1 p-2 shadow-sm" >
                     <div>
                         <a  :href="item.link" target="_blank" class="d-block" >
                            <img width="80" v-show="item.logo !== undefined" height="80" class="rounded-pill" :src="item.logo.thumbnails" :alt="item.title" :srcset="item.logo.medium_large ">
                        </a>
                    </div>
                    <div class="align-items-cente">
                        <a  :href="item.link" class="d-block my-2" target="_blank">{{ item.title }}</a>
                        <a  :href="item.link" class="d-block my-2" target="_blank">{{ item.link }}</a>
                    </div>
                   </div>
                </div>
             
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ShareSocial from "@/components/ShareSocial";
import Loading from "../components/Loading";
export default {
  name: "ServciePage",
  data() {
    return {
      isloading: false,
      sahreImage: null,
      slug: this.$route.params.slug, //this.$route.query("payment_id")
    };
  },
  components: { ShareSocial,Loading },
 
  methods: {
    ...mapActions("services", ["getService"]),
    },
  created() {
  
      this.isloading = true;
      this.getService(this.slug).then(()=>{
        this.sahreImage = this.service.image.medium_large;
      }).finally(() => {
        this.isloading = false;
      });
    },
    computed: {
    ...mapGetters("services", ["service"]),
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.our_servcies") + " | %s",
      meta: [{ property: "og:title", content: this.$t("title.our_servcies") }],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>