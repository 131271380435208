<template>
  <div>
    <!--my-donations-projects-item-->
    <div>
      <div class="my-4 py-3">
        <div
          class="
            my-donations-projects-item
            d-lg-flex
            justify-content-lg-between
            align-items-start
            border-bottom
          "
        >
          <div class="thumable me-2 col-lg-4">
            <router-link :to="{ path: 'products/' + item.project.slug }">
              <div
                v-if="item.is_completed"
                class="project-completed fs-7 rounded-pill edc-color-green"
              >
                <i class="fas fa-check"></i>
                <span class="d-inline ms-2">Donated</span>
              </div>
              <img
                :src="item.project.image.thumbnail"
                lazysizes
                :data-src="item.project.image.thumbnail"
                class="project-list-img img-fluid"
                alt=""
                srcset=""
              />
            </router-link>
          </div>
          <div class="details flex-fill mt-3 mt-lg-0">
            <h2 class="text-end fs-5 fw-bold mb-4 mx-2">
              <router-link :to="{ path: 'products/' + item.project.slug }">{{
                item.project.title
              }}</router-link>
            </h2>
            <!--progress-->
            <progress-and-details
              :project_id="parseInt(item.project.id)"
              :details="item.project.meta"
            ></progress-and-details>

            <!--// progress-->
            <div
              class="
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
                mb-2
              "
            >
              <!--buttons-->
              <div
                v-if="item.project.reports"
                class="
                  buttons
                  mt-4
                  d-lg-flex
                  justify-content-lg-between
                  align-items-lg-center
                "
              >
                <a
                  href="#"
                  class="
                    rounded-pill
                    d-block d-lg-inline
                    btn
                    edc-btn-primary
                    text-white
                    fs-5
                    py-2
                    px-4
                    text-center
                  "
                >
                  {{ $t("label.download_report") }}
                  <i class="fas fa-cloud-download-alt"></i>
                </a>
              </div>
              <!--//buttons-->
              <!--buttons show payment log-->

              <!--//buttons-->
              <div
                class="
                  edc-btn-outline-red
                  rounded-20
                  px-3
                  py-2
                
                  align-middle
                  d-flex
                "
              >
                <span>{{ $t("label.your_total_donate") }}</span>
                <count-up :endVal="parseInt(item.total)"></count-up>
              </div>
              <button
                @click.prevent="showLog()"
                class="btn mt-1 mt-lg-0 edc-btn-blue text-white rounded-pill"
                :class="{ 'edc-btn-secondary': isShowLogClass }"
              >
                {{ $t("label.show_log") }} (<b>{{ item.log.length }}</b
                >)
              </button>
            </div>
          </div>
        </div>

        <div class="my-donations-project-log" v-if="isShowLog">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("label.amount") }}</th>
                <th>{{ $t("label.donate") }}</th>
                <th>{{ $t("label.payment_id") }}</th>
                <!--<th>Traking ID</th>
                            <th>Reference ID</th>
                            <th>Transactions ID</th>-->
                <th>{{ $t("label.created_at") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(log, index) in item.log" :key="index">
                <td><count-up :endVal="parseInt(log.ammount)"></count-up></td>
                <td>{{ log.payment_type }}</td>
                <td>
                  {{ log.payment_id }}
                  <router-link
                    :to="{
                      path: '/result',
                      query: { payment_id: log.payment_id },
                    }"
                    target="_blank"
                    class="edc-color-primary"
                  >
                    <i class="fas fa-eye"></i
                  ></router-link>
                </td>
                <!--<td>{{ log.track_id }}</td>
                              <td>{{ log.reference_id }}</td>
                              <td>{{ log.transaction_id }}</td>-->
                <td>{{ log.created }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--//my-donations-projects-item-->
  </div>
</template>
<script>
import ProgressAndDetails from "@/components/ProgressAndDetails";
import vueLazysizes from "vue-lazysizes";

//import Auth from "../auth";

export default {
  name: "my-donations",
  components: {
    ProgressAndDetails,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isShowLog: false,
      isShowLogClass: false,
    };
  },
  methods: {
    showLog() {
      this.isShowLog = this.isShowLog === false;
      this.isShowLogClass = this.isShowLogClass === false;
    },
  },
  
  directives: {
    lazysizes: vueLazysizes
  },
};
</script>