<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <!--register-->
        <form @submit.prevent="doUpdateProfile">
          <div
            class="
              edc-content-round
              register-content
              rounded-50
              col-lg-5 col-sm-12
              m-auto
              p-3
            "
          >
            <div class="user-form">
              <div
                class="
                  user-form-header
                  mb-4
                  text-right
                  d-flex
                  align-items-center
                  justify-content-start
                  border-bottom border-1
                  pb-2
                "
              >
                <user-avatar :size="80"></user-avatar>
                <h1 class="h4 mx-2">
                  {{ $t("title.change_profile_details") }}
                </h1>
              </div>

              <div class="text-muted fs-6 my-3 edc-color-secondary">
                {{ $t("label.personal_info") }}
              </div>
              <label for="" class="form-label fs-7">{{
                $t("label.name")
              }}</label>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="name"
                >
                  <i class="fas fa-user" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  v-model="user.first_name"
                  required=""
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.name')"
                  aria-label="name"
                  aria-describedby="name"
                />
              </div>
              <label for="" class="form-label fs-7">{{
                $t("label.last_name")
              }}</label>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="last_name"
                >
                  <i class="fas fa-user" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  v-model="user.last_name"
                  required=""
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.last_name')"
                  aria-label="last_name"
                  aria-describedby="last_name"
                />
              </div>
              <label for="" class="form-label fs-7">{{
                $t("label.mobile")
              }}</label>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="mobile"
                >
                  <i class="fas fa-phone" aria-hidden="true"></i>
                </span>
                <input
                  type="tel"
                  v-model="user.mobile"
                  required=""
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.mobile')"
                  aria-label="mobile"
                  aria-describedby="mobile"
                />
              </div>
              <div class="text-muted fs-6 my-3 edc-color-secondary">
                {{ $t("label.user_info") }}
              </div>
              <label for="" class="form-label fs-7">{{
                $t("label.username")
              }}</label>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="username"
                >
                  <i class="fas fa-user" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  v-model="user.user_login"
                  required=""
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.username')"
                  aria-label="username"
                  aria-describedby="username"
                />
              </div>
              <label for="" class="form-label fs-7">{{
                $t("label.email")
              }}</label>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="email"
                >
                  <i class="fas fa-envelope" aria-hidden="true"></i>
                </span>
                <input
                  type="email"
                  v-model="user.user_email"
                  required=""
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.email')"
                  aria-label="email"
                  aria-describedby="email"
                />
              </div>
            </div>
            <div
              class="
                login-buttons
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <button
                type="submit"
                class="
                  btn
                  edc-btn-primary
                  flex-fill
                  text-white
                  rounded-pill
                  py-2
                  px-4
                "
              >
                {{ $t("btn.update_profile") }}
              </button>
            </div>
          </div>
        </form>
        <!--//register-->
      </div>
    </div>
  </div>
</template>

<script>
//TODO Form Validations
import { mapGetters, mapActions } from "vuex";
import UserAvatar from "../../components/UserAvatar.vue";
import store from "../../store/index";
export default {
  components: { UserAvatar },
  name: "ProfilePage",
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["updateProfile"]),
    doUpdateProfile() {
      let form = new FormData();
      form.append("email", this.user.user_email);
      form.append("first_name", this.user.first_name);
      form.append("last_name", this.user.last_name);
      form.append("nicname", this.user.user_nicename);
      form.append("website", this.user.website);
      form.append("mobile", this.user.mobile);
      this.updateProfile(form)
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            store.commit("auth/UPDATE_USER", response.data);
            this.$swal({
              icon: "success",
              title: this.$t("message.update_success"),
            });
          }
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: this.$t("message.update_fail"),
          });
        });
    },
  },
  created() {
    //this.getUser();
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.profile") + " | %s",
      meta: [
        { name: "viewport", content: "user-scalable=no" },
        { property: "og:title", content: this.$t("title.profile") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>