<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <!--register-->
        <form @submit.prevent="doChangePassword">
          <div
            class="
              edc-content-round
              register-content
              rounded-50
              col-lg-5 col-sm-12
              m-auto
              p-3
            "
          >
            <div class="user-form">
              <div
                class="
                  user-form-header
                  mb-4
                  text-right
                  d-block
                  border-bottom border-1
                  pb-2
                "
              >
                <h1 class="h4">{{ $t("title.change_password") }}</h1>
              </div>

              <div class="form-label fs-6">{{ $t("label.old_password") }}</div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="old_password"
                >
                  <i class="fas fa-key" aria-hidden="true"></i>
                </span>
                <input
                  type="password"
                  required=""
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.old_password')"
                  v-model="form.old_password"
                  aria-label="old_password"
                  aria-describedby="old_password"
                />
              </div>
              <div class="form-label fs-6">{{ $t("label.new_password") }}</div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="repet-password"
                >
                  <i class="fas fa-key" aria-hidden="true"></i>
                </span>
                <input
                  type="password"
                  required=""
                  v-model="form.new_password"
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.new_password')"
                  aria-label="new-password"
                  aria-describedby="new-password"
                />
              </div>
              <div class="form-label fs-6">
                {{ $t("label.repeat_password") }}
              </div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="repet-password"
                >
                  <i class="fas fa-key" aria-hidden="true"></i>
                </span>
                <input
                  type="password"
                  required=""
                  v-model="form.repeat_password"
                  class="form-control rounded-pill rounded-end"
                  :placeholder="$t('label.repeat_password')"
                  aria-label="repet-password"
                  aria-describedby="repet-password"
                />
              </div>
            </div>
            <div
              class="
                login-buttons
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <button
                type="submit"
                class="
                  btn
                  edc-btn-primary
                  flex-fill
                  text-white
                  rounded-pill
                  py-2
                  px-4
                "
              >
                {{ $t("btn.update") }}
              </button>
            </div>
          </div>
        </form>
        <!--//register-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ChangePasswordPage",
  data() {
    return {
      form: {},
    };
  },
  methods: {
    ...mapActions("auth", ["changePassword"]),
    doChangePassword() {
      let form = new FormData();

      form.append("new_password", this.form.new_password);
      form.append("old_password", this.form.old_password);
      form.append("repeat_password", this.form.repeat_password);

      this.changePassword(form)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              title: response.data.message,
            });
          } else {
            this.$swal({
              icon: "error",
              title: this.$t("message.change_password_fail"),
            });
          }
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.change_password") + " | %s",
      meta: [
        { name: "viewport", content: "user-scalable=no" },
        { property: "og:title", content: this.$t("title.change_password") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>