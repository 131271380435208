<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <loading :isShowLoading="isLoading"></loading>
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div
          v-if="isShow"
          class="
            edc-content-round
            my-donations-content
            rounded-50
            col-md-8 col-sm-12
            m-auto
            p-3 p-lg-5
          "
        >
          <div
            class="
              my-donations-content-head
              d-flex
              align-items-center
              border-bottom
              py-2
            "
          >
            <h1 class="h4">مرحباً</h1>
            <span class="edc-color-secondary">{{ auth.display_name }}</span>
            <div class="mx-2">
              <user-avatar :size="50" :isName="true"></user-avatar>
            </div>
            <h1 class="h4">هذة قائمة بالمشاريع التي تبرعت لها</h1>
          </div>
          <!--my-donations-projects-->
          <div class="my-donations-projects mt-5 mt-sm-1">
            <div>
              <div v-if="isEmpty" class="alert alert-danger">
                {{ $t("message.not_project_found") }}
              </div>
              <my-donations
                v-for="(item, index) in items"
                :key="index"
                :item="item"
              ></my-donations>
              <!--my-donations-loadmore-->
              <loading-page :isShow="isLoading"></loading-page>
              <div
                v-if="isShowLoadMoreButton"
                class="my-donations-loadmore my-3 py-3 text-center fs-5 fw-bold"
              >
                <a
                  href="#"
                  @click.prevent="loadMore()"
                  class="edc-color-primary"
                  ><span class="ms-4 d-inline">{{ $t("btn.load_more") }}</span>
                  <i class="fas fa-chevron-down"></i
                ></a>
              </div>
              <!--//my-donations-loadmore-->
            </div>
          </div>
          <!--//my-donations-projects-->

          <!--my-donations-content-bottom-->
          <div
            class="
              my-donations-content-bottom
              mx-5
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <router-link
              :to="{ path: '/projects' }"
              class="btn edc-btn-outline-gray rounded-pill py-3 px-5 me-5"
              >{{ $t("btn.show_project") }}</router-link
            >
            <router-link
              :to="{ path: '/' }"
              class="btn edc-btn-primary rounded-pill me-3 py-3 px-5 text-white"
            >
              <i class="fas fa-home ms-3"></i>
              {{ $t("btn.home") }}
            </router-link>
          </div>
          <!--//my-donations-content-bottom-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyDonations from "@/components/MyDonations.vue";
import Loading from "@/components/Loading";
import axios from "axios";
import UserAvatar from "../../components/UserAvatar.vue";
import LoadingPage from "@/components/LoadingPage.vue";
export default {
  name: "MyDonatePage",
  components: { MyDonations, Loading, UserAvatar, LoadingPage },
  data() {
    return {
      isEmpty: false,
      isShow: false,
      items: [],
      auth: {},
      offset: 0,
      limit: 20,
      isShowLoadMoreButton: false,
      isLoading: true,
      errorMessage: "",
    };
  },
  methods: {
    async get_list() {
      this.isLoading = true;
      await axios
        .get("my-donations", {
          params: {
            limit: this.limit,
            offset: this.offset,
          },
        })
        .then((response) => {
          this.isShow = true;
          for (let i = 0; i < response.data.projects.length; i++) {
            //console.log(response.data.projects[i]);
            this.items.push(response.data.projects[i]);
          }

          if (response.data.projects.length <= 0) {
            this.isShowLoadMoreButton = false;
          }
        })
        .catch((error) => {
          this.isShow = false;
          this.errorMessage = error.response.data;
        })
        .finally(() => {
          this.isLoading = false;
          if (this.items.length <= 0) {
            this.isEmpty = true;
          }
        });
    },
    async loadMore() {
      this.offset++;
      await this.get_list();
    },
  },
  created() {
    this.get_list();
    //this.auth = Auth.getAUthData();
  },
    mounted() {
        let _this = this;
        this.$root.$on("authLogin",(payload)=>{
            _this.get_list();
        });
        this.$root.$on("authLogout",()=>{
            _this.items = [];
        });
    },
  watch: {
    items: function (newData) {
      if (newData.length > 0) {
        this.isShowLoadMoreButton = true;
      }
    },
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.my_donate") + " | %s",
      meta: [
        { property: "og:title", content: this.$t("title.my_donate") },
      ],
    };
  },
};
</script>